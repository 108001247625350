<template>
  <div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="addJobForm">
      <div
        :class="
          isMd ? 'text-center ' : 'd-flex align-center justify-space-between'
        "
      >
        <span
          class="d-block font-24 font-700"
          :class="isMobile ? 'font-18 mb-2' : 'font-24 '"
          >{{ isEdit ? $t("forms.edit") : $t("forms.addNew") }}
          {{ $t("forms.jobExperience") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="isEdit ? $t('forms.save') : $t('forms.add')"
          :closeTitle="isEdit ? $t('forms.discard') : $t('forms.cancel')"
          @close="$emit('close')"
        ></dialog-btns>
        <img
          v-if="!isMd"
          class="cursor-pointer"
          @click="$emit('close')"
          :src="require('@/assets/images/dashboard/icons/close-black.svg')"
        />
      </div>
      <v-row class="mt-3">
        <!-- Medical Institute * -->
        <v-col cols="12">
          <base-input
            v-model="obj.company_name"
            :isRequired="true"
            :title="$t('forms.Institute')"
            :placeholder="$t('forms.Institute')"
            :rules="[(v) => !!v || $t('forms.require')]"
          ></base-input>
        </v-col>

        <!-- Job position * -->
        <v-col cols="12">
          <base-input
            v-model="obj.job_position"
            :isRequired="true"
            :title="$t('forms.jobPosition')"
            :placeholder="$t('forms.jobPosition')"
            :rules="[(v) => !!v || 'require']"
          ></base-input>
        </v-col>

        <!-- Graduation Date from * -->
        <v-col cols="6">
          <div class="mt-3">
            <span class="darktext--text font-16 font-600 d-block mb-2"
              >{{ $t("forms.fromDate") }}

              <span class="error--text">*</span>
            </span>
            <flat-pickr
              placeholder="yyyy-mm-dd"
              v-model="obj.from_date"
              :config="{
                maxDate: 'today',
              }"
            ></flat-pickr>
          </div>
        </v-col>

        <!-- Graduation Date to * -->
        <v-col cols="6">
          <div class="mt-3">
            <span class="darktext--text font-16 font-600 d-block mb-2"
              >{{ $t("forms.toDate") }}

              <span class="error--text">*</span>
            </span>
            <flat-pickr
              placeholder="yyyy-mm-dd"
              v-model="obj.to_date"
              :config="{
                maxDate: 'today',
                minDate: obj.from_date
              }"
            ></flat-pickr>
          </div>
        </v-col>
      </v-row>
      <dialog-btns
        v-if="!isMobile"
        :btnTitle="isEdit ? $t('forms.save') : $t('forms.add')"
        :closeTitle="isEdit ? $t('forms.discard') : $t('forms.cancel')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInput from "../BaseInput.vue";
import DialogBtns from "./DialogBtns.vue";
export default {
  props: {
    editItem: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: { DialogBtns, BaseInput },
  data: () => ({
    obj: {},
    valid: false,
  }),
  computed: {
    ...mapGetters(["countries"]),
  },
  methods: {
    dateHandler(event, type) {
      this.obj[type] = event;
    },
    async submitHandler() {
      let valid = this.$refs.addJobForm.validate();
    
      if (!valid || !this.obj.from_date || !this.obj.to_date) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth",
            });
        }, 100);
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let formData = new FormData();
      formData.append("company_name", this.obj.company_name);
      formData.append("job_position", this.obj.job_position);
      formData.append("from_date", this.obj.from_date);
      formData.append("to_date", this.obj.to_date);

      let res;
      if (this.isEdit) {
        formData.append("job_experience_id", this.editItem.id);
        res = await this.$axios.post("job/experience/update", formData);
      } else {
        res = await this.$axios.post("job/experience/add", formData);
      }

      if (res.data.success) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit("updateData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.obj = { ...this.editItem };
      this.obj.to_date = this.editItem.to_date.slice(0, 10);
      this.obj.from_date = this.editItem.from_date.slice(0, 10);
    }
  },
};
</script>

<style></style>
