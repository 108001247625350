<template>
  <div class="white radius-15 pa-2 pb-4">
    <profile-head
      :title="$t('dashboard.jobExperience')"
      :btnTitle="$t('dashboard.addNewJobExperience')"
      @click="openHandler('new')"
      :icon="require('@/assets/images/dashboard/icons/plus.svg')"
    ></profile-head>

    <div
      class="mt-4 bluebgdash"
      :class="isMobile ? ' pa-3' : ' px-9 py-6'"
      v-for="(item, i) in data"
      :key="i"
    >
      <!-- mobile btns -->
      <div class="text-right" v-if="isMobile">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              :src="
                require('@/assets/images/dashboard/icons/more-vertical.svg')
              "
              alt=""
            />
          </template>
          <v-list>
            <div
              class="d-flex align-center px-4 py-1 pr-9 my-1"
              @click="deleteHandler(item.id)"
            >
              <img
                class="mx-3"
                :src="
                  require('@/assets/images/dashboard/icons/minus-circle.svg')
                "
              />
              <span class="error--text font-16 font-700">{{
                $t("forms.delete")
              }}</span>
            </div>

            <!-- edit btn -->
            <div
              class="d-flex align-center px-4 py-1 pr-9 my-1 cursor-pointer"
              @click="editHandler(item)"
            >
              <img
                class="mx-3"
                :src="require('@/assets/images/dashboard/icons/edit.svg')"
              />
              <span class="darkprimary--text font-16 font-700">{{
                $t("forms.edit")
              }}</span>
            </div>
          </v-list>
        </v-menu>
      </div>

      <!-- desktop btns -->
      <div class="d-flex align-center justify-space-between" v-if="!isMobile">
        <span class="font-18 font-700">{{
          $t("dashboard.jobExperience")
        }}</span>
        <div class="d-flex align-center">
          <!-- delete btn -->
          <div
            class="d-flex align-center mx-7 cursor-pointer"
            @click="deleteHandler(item.id)"
          >
            <img
              class="mx-3"
              :src="require('@/assets/images/dashboard/icons/minus-circle.svg')"
            />
            <span class="error--text font-16 font-700">{{
              $t("forms.delete")
            }}</span>
          </div>

          <!-- edit btn -->
          <div
            class="d-flex align-center mr-7 cursor-pointer"
            @click="editHandler(item)"
          >
            <img
              class="mx-3"
              :src="require('@/assets/images/dashboard/icons/edit.svg')"
            />
            <span class="darkprimary--text font-16 font-700">{{
              $t("forms.edit")
            }}</span>
          </div>
        </div>
      </div>

      <v-row :class="isMobile ? ' mt-2 pb-2 px-2' :  !isComplete ? 'px-8 mt-4':'mt-3'">
        <!-- company_name -->
        <v-col :lg="isComplete ? 4 : 2" :md="3" :cols="12">
          <base-info
            :title="$t('forms.Institute')"
            :value="item.company_name"
          ></base-info>
        </v-col>

        <!-- job_position -->
        <v-col :lg="isComplete ? 4 : 3" :md="3" :cols="12">
          <base-info
            :title="$t('forms.jobPosition')"
            :value="item.job_position"
          ></base-info>
        </v-col>

        <!-- from_date -->
        <v-col :lg="isComplete ? 4 : 3" :md="3" :cols="12">
          <base-info
            :title="$t('forms.graduationDateFrom')"
            :value="item.from_date.slice(0, 10)"
          ></base-info>
        </v-col>

        <!-- to_date -->
        <v-col :lg="isComplete ? 4 : 2" :md="3" :cols="12">
          <base-info
            :title="$t('forms.graduationDateTo')"
            :value="item.to_date.slice(0, 10)"
          ></base-info>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="dialog"
      :key="dialog"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
      max-width="575px"
    >
      <new-job
        @updateData="getUpdated"
        @close="dialog = false"
        :isEdit="isEdit"
        :editItem="editItem"
      ></new-job>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-item
        @close="deleteDialog = false"
        @ok="okHandler"
        url="job/experience/delete"
        :obj="selectedDeleteObj"
      ></delete-item>
    </v-dialog>
  </div>
</template>

<script>
import DeleteItem from "../../../../../components/core/DeleteItem.vue";
import BaseInfo from "../../../components/BaseInfo.vue";
import NewJob from "../../../components/dialogs/NewJob.vue";
import ProfileHead from "../../../components/ProfileHead.vue";
export default {
  props: {
    isComplete: {
      type: Boolean,
      default: false,
    },
  },
  components: { ProfileHead, BaseInfo, NewJob, DeleteItem },
  data: () => ({
    deleteDialog: false,
    dialog: false,
    isEdit: false,
    data: [],
    selectedDeleteObj: {},
  }),
  methods: {
    openHandler(type) {
      this.dialog = true;
      if (type == "new") {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },
    deleteHandler(id) {
      this.selectedDeleteObj = { key: "job_experience_id", id };
      this.deleteDialog = true;
    },
    editHandler(item) {
      this.dialog = true;
      this.isEdit = true;
      this.editItem = item;
    },
    async okHandler() {
      this.deleteDialog = false;
      await this.getData();
    },
    async getData() {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "job/experiences";
      }
      let { data } = await this.$axios.get(url);
      this.data = data.data.experiences;
    },
    async getUpdated() {
      this.dialog = false;
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
